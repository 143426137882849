<ion-input
  (ionInput)="onPasswordChange($event)"
  (keyup.enter)="onConfirm()"
  [class.white]="value"
  type="{{ showPassword ? 'text' : 'password' }}"
  [value]="value"
  [class.error]="showError"
  [placeholder]="placeholder">
  <img [src]="passwordIcon" slot="end" (click)="togglePasswordVisibility()" />
</ion-input>

@if (value && showValidation) {
  <div [@fadeInOut]>
    <div class="password-rule">
      <img src="{{ passwordRuleErrors.length ? 'assets/icon/green-check.svg' : 'assets/icon/background-yellow-close.svg' }}" alt="" />
      <div>{{ 'PWA_createAccount_passwordRequirements_Item1' | translate }}</div>
    </div>
    <div class="password-rule">
      <img src="{{ passwordRuleErrors.symbol ? 'assets/icon/green-check.svg' : 'assets/icon/background-yellow-close.svg' }}" alt="" />
      <div>{{ 'PWA_createAccount_passwordRequirements_Item2' | translate }}</div>
    </div>
    <div class="password-rule">
      <img src="{{ passwordRuleErrors.capital ? 'assets/icon/green-check.svg' : 'assets/icon/background-yellow-close.svg' }}" alt="" />
      <div>{{ 'PWA_createAccount_passwordRequirements_Item3' | translate }}</div>
    </div>
  </div>
}
