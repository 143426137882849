import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserProvider } from '../../providers/user.provider';
import { ToastService, ToastType } from '../../services/toast.service';
import { Router } from '@angular/router';
import { UserLocationState } from '../../store/user-location.state';
import { LanguageState } from '../../store/language.state';
import { DefaultProjectState } from '../../store/default-project.state';
import { EMPTY, firstValueFrom, Subject, switchMap, takeUntil } from 'rxjs';
import { fadeIn } from '../../utils/animations';
import { AuthenticationPasswordComponent } from './authentication-password/authentication-password.component';
import { AuthenticationStepService } from './authentication-step.service';
import { IonButton, IonCheckbox, IonInput, IonSpinner, NavController } from '@ionic/angular/standalone';
import {AppService, PlatformType} from '../../services/app.service';
import { InputErrorComponent } from '../input-error/input-error.component';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { AppState } from '../../store/app.state';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AnalyticsEventType } from '../../services/analytics/analytics.model';
import { AsyncPipe } from '@angular/common';
import { SocialLoginService } from '../../services/social-login.service';
import { Capacitor } from '@capacitor/core';
import { ProfileState } from '../../store/profile.state';

export enum AuthenticationSteps {
  EMAIL,
  LOGIN,
  CREATE_ACCOUNT,
  ACCOUNT_CREATED,
  FORGOT_PASSWORD,
  FORGOT_PASSSWORD_CONFIRM,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  EMAIL_NOT_VALIDATED,
  EMAIL_VALIDATED,
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  animations: [fadeIn],
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    IonInput,
    TranslateModule,
    ButtonComponent,
    InputErrorComponent,
    AuthenticationPasswordComponent,
    IonCheckbox,
    AsyncPipe,
    IonSpinner,
    IonButton,
  ],
})
export class AuthenticationComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput') emailInput!: IonInput;
  @Input() resetPasswordData?: { uuid: string; jwt: string }; // If the reset password token is provided by url query parameter, we display the forgot password reset step directly
  @Input() confirmEmailToken?: string; // If the confirmation email link is provided with jwt, we display the email confirmed screen

  @Output() closePage = new EventEmitter<void>();

  constructor(
    public authenticationStepService: AuthenticationStepService,
    public socialLoginService: SocialLoginService,
    public appService: AppService,
    private userProvider: UserProvider,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router,
    private userService: UserService,
    private userLocationState: UserLocationState,
    private languageState: LanguageState,
    private projectState: DefaultProjectState,
    private ref: ChangeDetectorRef,
    private appState: AppState,
    private analyticsService: AnalyticsService,
    private profileState: ProfileState,
    private nav: NavController,
  ) {}

  private destroyed$ = new Subject<void>();
  readonly authenticationSteps = AuthenticationSteps;

  showError = false;
  customError = '';
  isLoading = false;
  displayCoppaCheckBox = this.userLocationState.userLocation$.value.countryCode === 'US';

  authenticationForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    forgotPasswordEmail: new FormControl('', [Validators.required, Validators.email]),
    signinPassword: new FormControl('', [Validators.required]),
    signupPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/(?=.*[A-Z])/),
      Validators.pattern(/^(?=.*[\d\W]).+$/),
    ]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/(?=.*[A-Z])/),
      Validators.pattern(/^(?=.*[\d\W]).+$/),
    ]),
    terms: new FormControl(false, [Validators.requiredTrue]),
    coppa: new FormControl(false, [Validators.requiredTrue]),
  });

  socialLoginLoading = false;

  ngOnInit(): void {
    if (!this.socialLoginService.inizialized$.value) {
      void this.socialLoginService.initialize();
    }

    this.authenticationStepService.initialize(!!this.resetPasswordData?.jwt && !!this.resetPasswordData.uuid, !!this.confirmEmailToken);
    this.ref.detectChanges();

    this.authenticationStepService.currentStep$?.pipe(takeUntil(this.destroyed$)).subscribe(currentStep => {
      if (currentStep.step === AuthenticationSteps.EMAIL && !this.appService.isMobile$.value) {
        setTimeout(() => void this.emailInput?.setFocus(), 50);
      }

      this.showError = false;
      this.customError = '';
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  goToProfileSelection(): void {
    this.closePage.emit();
    void this.nav.navigateRoot('profile/select', {
      replaceUrl: true,
      state: {
        hideHeader: true,
      },
    });
  }

  checkEmail(): void {
    if (this.authenticationForm.get('email')?.invalid) {
      this.showError = true;
      return;
    }

    this.showError = false;
    this.customError = '';
    this.isLoading = true;

    this.userProvider.doesUserExist(this.authenticationForm.get('email')?.value as string).subscribe({
      next: exists => {
        if (!exists) {
          this.goToStep(AuthenticationSteps.CREATE_ACCOUNT);
        } else {
          this.goToStep(AuthenticationSteps.LOGIN);
        }
        this.isLoading = false;
        this.ref.detectChanges();
      },
      error: error => this.catchHttpError(error),
    });
  }

  login(): void {
    this.isLoading = true;

    if (this.authenticationForm.get('signinPassword')?.invalid) {
      this.isLoading = false;
      this.showError = true;
      this.ref.detectChanges();
      return;
    }

    this.userProvider
      .loginWithEmail(this.authenticationForm.get('email')?.value as string, this.authenticationForm.get('signinPassword')?.value as string)
      .subscribe({
        next: async user => {
          this.isLoading = false;
          this.showError = false;
          this.customError = '';
          await this.userService.login(user);

          if (!user.isEmailValidated) {
            this.authenticationStepService.setStep(AuthenticationSteps.EMAIL_NOT_VALIDATED);
          } else {
            void this.nav.navigateRoot('profile/select', {
              state: {
                hideHeader: true,
              },
            });
            this.closePage.emit();
          }

          // Send Login event
          this.analyticsService.sendEvent(AnalyticsEventType.Login, { authProvider: 'worldreader' });
        },
        error: error => this.catchHttpError(error),
      });
  }

  resetPassword(): void {
    const email = this.authenticationForm.get('forgotPasswordEmail')?.value;
    if (this.authenticationForm.get('forgotPasswordEmail')?.invalid || !email) {
      this.showError = true;
      return;
    }
    this.isLoading = true;

    this.userProvider
      .doesUserExist(email)
      .pipe(
        switchMap(exists => {
          if (exists) {
            // Reset password request
            return this.userProvider.resetPassword(email);
          } else {
            this.showError = true;
            this.isLoading = false;
            this.ref.detectChanges();
            return EMPTY;
          }
        }),
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.goToStep(AuthenticationSteps.FORGOT_PASSSWORD_CONFIRM);
        },
        error: error => this.catchHttpError(error),
      });
  }

  saveNewPassword(): void {
    if (this.authenticationForm.get('newPassword')?.invalid) {
      this.showError = true;
      return;
    }

    this.isLoading = true;

    this.userProvider
      .changePassword(
        this.authenticationForm.get('newPassword')?.value as string,
        this.resetPasswordData!.uuid as string,
        this.resetPasswordData!.jwt as string,
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.goToStep(AuthenticationSteps.RESET_PASSWORD_CONFIRM);
        },
        error: error => this.catchHttpError(error),
      });
  }

  createAccount(): void {
    if (
      this.authenticationForm.get('signupPassword')?.invalid ||
      this.authenticationForm.get('terms')?.invalid ||
      (this.displayCoppaCheckBox && this.authenticationForm.get('coppa')?.invalid)
    ) {
      this.showError = true;
      return;
    }

    this.isLoading = true;

    this.userProvider
      .createAccount({
        email: this.authenticationForm.value.email as string,
        password: this.authenticationForm.value.signupPassword as string,
        acceptedTerms: this.authenticationForm.value.terms as boolean,
        acceptedCoppa: this.authenticationForm.value.coppa as boolean,
        country: this.userLocationState.userLocation$.value.countryCode,
        language: this.languageState.language$.value.selected,
        projectCode: this.projectState.defaultProject$.value.code,
      })
      .subscribe({
        next: user => {
          this.isLoading = false;
          void this.userService.login(user);
          this.goToStep(AuthenticationSteps.ACCOUNT_CREATED);

          // Send Login event
          this.analyticsService.sendEvent(AnalyticsEventType.Registration, { authProvider: 'worldreader' });
        },
        error: error => this.catchHttpError(error),
      });
  }

  goToStep(step: AuthenticationSteps): void {
    if (step === AuthenticationSteps.LOGIN) {
      // Reset password form
      this.authenticationForm.get('signupPassword')?.setValue('');
      this.authenticationForm.get('signinPassword')?.setValue('');
      this.showError = false;
      this.customError = '';
    }
    this.authenticationStepService.setStep(step);

    this.ref.detectChanges();
  }

  startReading(): void {
    this.closePage.emit();
    void this.router.navigate(['/home']);
  }

  clearEmail(): void {
    this.authenticationForm.get('email')?.setValue('');
  }

  createProfile(): void {
    void this.closePage.emit();
    void this.nav.navigateRoot('/profile/create', { state: { hideHeader: true } });
  }

  goToHome(): void {
    void this.closePage.emit();
    void this.router.navigate(['home']);
  }

  loginWithFacebook(): void {
    this.socialLoginLoading = true;
    if (this.socialLoginService.inizialized$.value) {
      this.socialLoginService
        .facebookLogin()
        .then(async isNewUser => {
          this.socialLoginLoading = false;
          this.ref.detectChanges();

          void this.redirectAfterSocialLogin(isNewUser || false);
        })
        .catch(() => {
          // Errors are already catcher in the socialLoginService. We can just ignore any error coming here
          this.socialLoginLoading = false;
          this.ref.detectChanges();
          return;
        });
    }
  }

  loginWithApple(): void {
    this.socialLoginLoading = true;
    if (this.socialLoginService.inizialized$.value) {
      void this.socialLoginService
        .appleLogin()
        .then(async isNewUser => {
          this.socialLoginLoading = false;
          this.ref.detectChanges();

          void this.redirectAfterSocialLogin(isNewUser || false);
        })
        .catch(() => {
          // Errors are already catcher in the socialLoginService. We can just ignore any error coming here
          this.socialLoginLoading = false;
          this.ref.detectChanges();
          return;
        });
    }
  }

  loginWithGoogle(): void {
    this.socialLoginLoading = true;
    if (this.socialLoginService.inizialized$.value) {
      this.socialLoginService
        .googleLogin()
        .then(async isNewUser => {
          this.socialLoginLoading = false;
          this.ref.detectChanges();

          void this.redirectAfterSocialLogin(isNewUser || false);
        })
        .catch(() => {
          // Errors are already catcher in the socialLoginService. We can just ignore any error coming here
          this.socialLoginLoading = false;
          this.ref.detectChanges();
          return;
        });
    }
  }

  private async redirectAfterSocialLogin(isNewUser: boolean): Promise<void> {
    if (isNewUser) {
      void this.nav.navigateRoot('profile/create', { state: { hideHeader: true } });
    } else {
      const profiles = await firstValueFrom(this.profileState.profiles$);
      void this.nav.navigateRoot(profiles.length > 1 ? 'profile/select' : 'profile/create', { state: { hideHeader: true } });
    }

    this.closePage.emit();
    this.socialLoginLoading = false;
    this.ref.detectChanges();
  }

  resendEmail(): void {
    if (this.isLoading) {
      return;
    }

    if (!this.authenticationStepService.canRequestConfirmationEmail()) {
      void this.toastService.present({
        message: this.translateService.instant('PWA_toast_confirmationEmail_timeLimit'),
        type: ToastType.Negative,
        displayClose: true,
      });
      return;
    }

    this.isLoading = true;

    this.userProvider.resendConfirmationEmail().subscribe({
      next: () => {
        this.isLoading = false;
        void this.appState.set({ lastConfirmationEmailRequest: new Date() });

        void this.toastService.present({
          message: this.translateService.instant('PWA_toast_confirmationEmail_emailSent'),
          type: ToastType.Positive,
          displayClose: true,
        });

        this.ref.detectChanges();
      },
      error: error => this.catchHttpError(error),
    });
  }

  private catchHttpError(error: HttpErrorResponse): void {
    this.isLoading = false;
    this.ref.detectChanges();

    if (error.error?.message || error?.message) {
      this.showError = true;
      this.customError = error.error?.message || error?.message;
      this.ref.detectChanges();
    } else {
      throw error;
    }
  }

  protected readonly Capacitor = Capacitor;
  protected readonly PlatformType = PlatformType;
}
