import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, UserType } from '../store/user.state';
import { UrlBuilder } from '../utils/url-builder';

enum ApiEndpoint {
  LoginFaceBook = '/auth/facebook',
  LoginGoogle = '/auth/google',
  LoginApple = '/auth/apple',
}

export interface SocialUser extends User {
  isNewAccount: boolean;
}

@Injectable({ providedIn: 'root' })
export class SocialLoginProvider {
  private http = inject(HttpClient);

  loginWithFacebook(facebookId: string, accessToken: string, country: string): Observable<SocialUser> {
    const url = new UrlBuilder(ApiEndpoint.LoginFaceBook).setQueryParams({ country }).getUrl();

    return this.http
      .post<{ jwt: string; userId: string; userType: UserType; isNewAccount: boolean }>(environment.usersBaseUrl + url, { facebookId, accessToken })
      .pipe(
        map(user => ({ id: user.userId, jwt: user.jwt, userType: UserType.FACEBOOK, isNewAccount: user.isNewAccount })),
        catchError(error => throwError(() => error.error)),
      );
  }

  // TODO Backend not ready
  loginWithApple(): Observable<User> {
    return of({
      id: '1',
      jwt: '1',
      userType: UserType.APPLE,
    });
  }

  loginWithGoogle(googleId: string, googleToken: string, platform: string, country: string): Observable<SocialUser> {
    const url = new UrlBuilder(ApiEndpoint.LoginGoogle).setQueryParams({ country }).getUrl();

    return this.http
      .post<{
        jwt: string;
        userId: string;
        userType: UserType;
        isNewAccount: boolean;
      }>(environment.usersBaseUrl + url, { googleId, googleToken, platform })
      .pipe(
        map(user => ({ id: user.userId, jwt: user.jwt, userType: UserType.GOOGLE, isNewAccount: user.isNewAccount })),
        catchError(error => throwError(() => error.error)),
      );
  }
}
