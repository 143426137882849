import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationSteps } from './authentication.component';
import { TranslateService } from '@ngx-translate/core';
import { UserState } from '../../store/user.state';
import { AppState } from '../../store/app.state';
import { differenceInMinutes } from 'date-fns';
import { AnalyticsEventType, PageViewEvents } from '../../services/analytics/analytics.model';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationStepService {
  constructor(
    private translateService: TranslateService,
    private userState: UserState,
    private appState: AppState,
    private analyticsService: AnalyticsService,
  ) {}

  currentStep$?: BehaviorSubject<{
    step: AuthenticationSteps;
    title: string;
    showBack: boolean;
  }>;

  initialized$ = new BehaviorSubject<boolean>(false);

  initialize(resetPasswordData: boolean, hasValidConfirmEmailToken: boolean): void {
    // Calculate initial screen
    let initialStep: AuthenticationSteps;

    if (resetPasswordData) {
      initialStep = AuthenticationSteps.RESET_PASSWORD;
    } else if (hasValidConfirmEmailToken) {
      initialStep = AuthenticationSteps.EMAIL_VALIDATED;
      void this.userState.set({ isEmailValidated: true });
    } else {
      initialStep = AuthenticationSteps.EMAIL;
    }

    this.currentStep$ = new BehaviorSubject({
      step: initialStep as AuthenticationSteps,
      title: this.getStepTitle(initialStep),
      showBack: this.showBackButton(initialStep),
    });

    this.sendAnalytics(initialStep);

    this.initialized$.next(true);
  }

  setStep(step: AuthenticationSteps): void {
    this.sendAnalytics(step);
    this.currentStep$!.next({
      step,
      title: this.getStepTitle(step),
      showBack: this.showBackButton(step),
    });
  }

  canRequestConfirmationEmail(): boolean {
    const lastRequestDate = this.appState.app$.value.lastConfirmationEmailRequest;

    if (!lastRequestDate) {
      return true;
    }

    const diffInMinutes = differenceInMinutes(new Date(), lastRequestDate);

    return diffInMinutes >= 5;
  }

  private getStepTitle(step: AuthenticationSteps): string {
    switch (step) {
      case undefined:
      case AuthenticationSteps.EMAIL:
        return this.translateService.instant('PWA_LoginOrCreateAccount_header');
      case AuthenticationSteps.FORGOT_PASSWORD:
      case AuthenticationSteps.FORGOT_PASSSWORD_CONFIRM:
        return this.translateService.instant('forgotLogin_header');
      case AuthenticationSteps.LOGIN:
      case AuthenticationSteps.EMAIL_NOT_VALIDATED:
        return this.translateService.instant('PWA_Login_header');
      case AuthenticationSteps.ACCOUNT_CREATED:
        return this.translateService.instant('PWA_createAccount_confirmation_header');
      case AuthenticationSteps.CREATE_ACCOUNT:
        return this.translateService.instant('PWA_createAccount_header');
      case AuthenticationSteps.RESET_PASSWORD:
      case AuthenticationSteps.RESET_PASSWORD_CONFIRM:
        return this.translateService.instant('PWA_resetPassword_header');
      default:
        return '';
    }
  }

  private showBackButton(step: AuthenticationSteps): boolean {
    switch (step) {
      case undefined:
      case AuthenticationSteps.EMAIL:
      case AuthenticationSteps.ACCOUNT_CREATED:
      case AuthenticationSteps.FORGOT_PASSSWORD_CONFIRM:
      case AuthenticationSteps.RESET_PASSWORD:
      case AuthenticationSteps.RESET_PASSWORD_CONFIRM:
      case AuthenticationSteps.EMAIL_NOT_VALIDATED:
        return false;
      case AuthenticationSteps.LOGIN:
      case AuthenticationSteps.CREATE_ACCOUNT:
      case AuthenticationSteps.FORGOT_PASSWORD:
        return true;
      default:
        return false;
    }
  }

  private sendAnalytics(step: AuthenticationSteps): void {
    let screenName: PageViewEvents | null;

    switch (step) {
      case AuthenticationSteps.EMAIL:
        screenName = PageViewEvents.AuthenticationEmailStep;
        break;
      case AuthenticationSteps.LOGIN:
        screenName = PageViewEvents.AuthenticationLoginStep;
        break;
      case AuthenticationSteps.CREATE_ACCOUNT:
        screenName = PageViewEvents.AuthenticationCreateAccountStep;
        break;
      case AuthenticationSteps.ACCOUNT_CREATED:
        screenName = PageViewEvents.AuthenticationAccountCreatedStep;
        break;
      case AuthenticationSteps.FORGOT_PASSWORD:
        screenName = PageViewEvents.AuthenticationForgotPasswordStep;
        break;
      case AuthenticationSteps.FORGOT_PASSSWORD_CONFIRM:
        screenName = PageViewEvents.AuthenticationForgotPasswordConfirmStep;
        break;
      case AuthenticationSteps.RESET_PASSWORD:
        screenName = PageViewEvents.AuthenticationResetPasswordStep;
        break;
      case AuthenticationSteps.RESET_PASSWORD_CONFIRM:
        screenName = PageViewEvents.AuthenticationResetPasswordConfirmStep;
        break;
      case AuthenticationSteps.EMAIL_VALIDATED:
        screenName = PageViewEvents.AuthenticationEmailValidatedStep;
        break;
      case AuthenticationSteps.EMAIL_NOT_VALIDATED:
        screenName = PageViewEvents.AuthenticationEmailNotValidatedStep;
        break;
      default:
        console.error('Missing authentication step for analytics' + step);
        screenName = null;
    }

    if (screenName) {
      this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName });
    }
  }
}
